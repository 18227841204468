<template>
    <el-container>
        <el-header class="flex items-center justify-between shadow">
            <Logo></Logo>
            <el-menu router mode="horizontal" :default-active="$route.path" :ellipsis="false">
                <el-menu-item index="/">Onlyfans Downloader</el-menu-item>
                <el-menu-item index="/fansly-downloader">
                    Fansly Downloader
                </el-menu-item>
                <el-menu-item index="/all-downloaders">All Downloaders</el-menu-item>
                <el-menu-item index="/blog">Blog</el-menu-item>
                <el-menu-item v-if="store.user?._id" @click="logout()">Logout</el-menu-item>
            </el-menu>
        </el-header>
        <div class="line"></div>
        <el-container style="min-height: calc(100vh - 110px)" class="bg-gray-100 h-full">
            <el-main>
                <slot/>
            </el-main>
        </el-container>
        <el-footer height="50px" class="flex items-center bg-gray-200">
            <div>Anyloader</div> <div class="mx-2">/ </div>
            <router-link to="/logs" style="color: inherit">Logs</router-link>
        </el-footer>
    </el-container>
</template>

<script setup>
let store = useStore()

onMounted (async () => {
	let {result} = await useApi('/user/me', {
		headers: {
			'x-jwt-token': localStorage.getItem('jwt')
		}
	})
	store.user = result
})

const logout = () => {
    store.user = null
    localStorage.removeItem('jwt')
}
</script>

<style>
.el-menu {
    border-bottom: none!important;
}

</style>