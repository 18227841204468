<template>
<div class="logo text-2xl flex gap-2"> 
  <!-- <ElIconDownload width="30" class="text-green-800"></ElIconDownload> -->
  <router-link to="/">
    <span class="font-bold upper bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
		Anyloader
	</span>
  </router-link>
</div>
</template>

<style>
.logo {
  animation: 1s appear;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}
</style>
